import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font, grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { flexCenter } from 'style/global.styles';

export const root = css`
  padding: ${spacing(80)} 0 ${spacing(120)};
  background: hsl(var(--color-text-1));
  color: hsl(var(--color-grayscale-1));
  height: 100%;
`;

export const title = css`
  margin-bottom: ${spacing(60)};
  display: flex;
  align-items: center;
`;

export const messages = css`
  background: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-textLight-7));
  ${font.size.tiny};
  padding: ${spacing(2)} ${spacing(12)};
  border-radius: ${spacing(61)};
  margin-left: ${spacing(8)};
`;

export const messageUnread = css`
  ${messages};
  background: hsl(var(--color-error-5));
  color: hsl(var(--color-white-11));
`;

export const contentWrapper = css`
  @media ${breakpoints.medium} {
    ${grid.display};
    ${grid.template.cols12};
    column-gap: ${spacing(32)};
  }
`;

export const conversationsWrapper = css`
  @media ${breakpoints.medium} {
    ${grid.colSpan.c5};
  }

  @media ${breakpoints.large} {
    ${grid.colSpan.c4};
  }
`;

export const messagesWrapper = css`
  @media ${breakpoints.smallMax} {
    display: none;
  }

  @media ${breakpoints.medium} {
    ${grid.colSpan.c7};
  }

  @media ${breakpoints.large} {
    ${grid.colSpan.c6};
    ${grid.colStart.c6};
  }
`;

export const noMessages = css`
  ${flexCenter};
  ${grid.colSpan.c12};
`;

export const skeletonItem = css`
  min-height: ${spacing(72)};
  margin-bottom: ${spacing(12)};
  border-radius: ${spacing(3)};
`;
